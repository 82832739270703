import React from 'react'
import './App.css';
import Container from '@mui/material/Container';

function Tjanster() {
  return (
    <div className="Test3">
      <Container>
      <header className="App-header" style={{backgroundColor: "white"}}>
        <h1>Tjänster</h1>
        Ofta får säljavdelningarna inte bra mjukvaror då IT och ekonomi är dominerande i köpprocesserna. <br /><br />
        Zeyls hjälper säljchefer med mjukvaror som löser utmaningarna som sälj har. <br />
        
      </header>
      </Container>
    </div>
  );
}

export default Tjanster;