import React from 'react'
import './App.css';

function Teknologier() {
  return (
    <div className="Test3">
      <header className="App-header" style={{backgroundColor: "white"}}>
        <h1>Teknologier</h1>
        React • C#.net • SQL <br /><br />
        VBA för excel <br /><br />
        Utveckling både i Sverige och offshore <br /><br />
      </header>
    </div>
  );
}

export default Teknologier;