import React from 'react'
import './App.css';
import Container from '@mui/material/Container';

function Kontakt() {
  return (
    <div className="Test3">
      <Container>
      <header className="App-header" style={{backgroundColor: "white"}}>
        <h1>Kontakt</h1>
        Zeyls<br />
        +46 730 43 57 16<br />
        support@zeyls.com <br />
        <br /><br />
        Zeyls AB - 556951-8391 - Malvastigen 7D, 192 54 Sollentuna, Sweden
      </header>
      </Container>
    </div>
  );
}

export default Kontakt;