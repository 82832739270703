import React from 'react'
import './App.css';
import Container from '@mui/material/Container';
import bidkalkylatorlogo from './assets/bidq.png';
import managebonuslogo from './assets/managebonuslogo.png';


function Produkter() {
  return (
    <div className="Test3">
      <Container maxWidth="xl"> 
      <header className="App-header" style={{backgroundColor: "white"}}>
        <h1>Bidkalkylator</h1>
        Bidkalkylator används för att beräkna lönsamhet på offerter samt för att godkänna kalkylen. Verktyget anpassas till den specifika prissättningsstrukturen på bolaget. 
        
         <br /><br /><br /><br />
        <img className="thumbnail-language pull-left" src={managebonuslogo} alt="Language" /><br />
        Manage Bonus är appen för att sätta KPIer och hantera bonusar för anställda. <br /><br />
        <a href="https://www.managebonus.com" style={{color: "blue"}}>ManageBonus.com</a>
      </header>
      </Container>
    </div>
  );
}

export default Produkter;