import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import zeylslogo from '../assets/zeylslogo.png';
import { useNavigate } from 'react-router-dom';


const NavigationBar = props => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
 
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  
  const handleSelectHome = () => { navigate('/'); setAnchorElNav(null); }; 
  const handleSelectProdukter = () => { navigate('/produkter'); setAnchorElNav(null);};
  const handleSelectTjanster = () => { navigate('/tjanster'); setAnchorElNav(null);};
  const handleSelectTeknologier = () => { navigate('/teknologier'); setAnchorElNav(null);};
  const handleSelectUtmaning = () => { navigate('/utmaning'); setAnchorElNav(null);};
  const handleSelectKontakt = () => { navigate('/kontakt'); setAnchorElNav(null); };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl"> 
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Button key='zeylslogo' onClick={handleSelectHome} >
                <img style={{height: "52px", padding: "0px 0px 0px 0px", position: 'relative', top: 0, left: 0 }} src={zeylslogo} alt="Language" />
            </Button>
            
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal:  'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleSelectProdukter}>Produkter</MenuItem>
              <MenuItem onClick={handleSelectTjanster}>Tjänster</MenuItem>
              <MenuItem onClick={handleSelectTeknologier}>Teknologier</MenuItem>
              <MenuItem onClick={handleSelectUtmaning}>Utmaning</MenuItem>
              <MenuItem onClick={handleSelectKontakt}>Kontakt</MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <Button key='zeylslogo2' onClick={handleSelectHome} >
                <img style={{height: "52px", padding: "0px 0px 0px 0px", position: 'relative', top: 0, left: 0 }} src={zeylslogo} alt="Language" />
            </Button>

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button key='produkter' onClick={handleSelectProdukter} sx={{ my: 2, color: 'white', display: 'block' }} >Produkter </Button>
          <Button key='tjanster' onClick={handleSelectTjanster} sx={{ my: 2, color: 'white', display: 'block' }} >Tjänster </Button>
          <Button key='teknologier' onClick={handleSelectTeknologier} sx={{ my: 2, color: 'white', display: 'block' }} >Teknologier </Button>
          <Button key='utmaning' onClick={handleSelectUtmaning} sx={{ my: 2, color: 'white', display: 'block' }} >Utmaning </Button>
          <Button key='kontakt' onClick={handleSelectKontakt} sx={{ my: 2, color: 'white', display: 'block' }} >Kontakt </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavigationBar;
