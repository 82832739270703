import React from 'react'
import './App.css';
import Container from '@mui/material/Container';

function Utmaning() {
  return (
    <div className="Test3">
      <Container>
      <header className="App-header" style={{backgroundColor: "white"}}>
        <h1>Utmaning</h1>
        Om du har ett problem inom sälj som en mjukvara skulle kunna lösa, hör av dig. Det är dig som vi vill hjälpa. <br /> 
      </header>
      </Container>
    </div>
  );
}

export default Utmaning;