import { Routes, Route} from "react-router-dom";
import './App.css';
import Home  from './Home';
import Produkter  from './produkter';
import Tjanster  from './tjanster';
import Teknologier  from './teknologier';
import Kontakt  from './kontakt';
import Utmaning  from './utmaning';
import NavigationBar from './components/NavigationBar';

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produkter" element={<Produkter />} />
        <Route path="/tjanster" element={<Tjanster />} />
        <Route path="/teknologier" element={<Teknologier />} />
        <Route path="/utmaning" element={<Utmaning />} />
        <Route path="/kontakt" element={<Kontakt />} />
      </Routes>
    </div>
  );
}

export default App;
